<template>
  <div>
    <div v-if="showPage === 1">
      <a-card :bordered="false">
        <a-steps :current="step">
          <a-step status="process" title="上传充值订单表格">
          <span slot="description" class="etc-recharge-payment-template-downLoad" style="color: #448df7"
                @click="downLoadTemplate">模板下载</span>
          </a-step>
          <a-step status="process" title="确认充值订单" />
          <a-step status="process" title="完成打款" />
          <a-step status="process" title="通知司机写卡充值" />
        </a-steps>
      </a-card>
      <a-card :bordered="false" style="margin-top: 10px">
        <a-form-model :model="searchObj" layout="inline">
          <a-form-model-item label="订单开始时间">
            <a-date-picker :locale="locale" :default-value="moment(searchObj.beginTime, dateFormat)"
                           @change="onChangeStartTime"
                           placeholder="请选择时间" show-time />
          </a-form-model-item>
          <a-form-model-item label="订单结束时间">
            <a-date-picker :locale="locale" :default-value="moment(searchObj.endTime, dateFormat)"
                           @change="onChangeEndTime"
                           placeholder="请选择时间" show-time />
          </a-form-model-item>
          <a-form-model-item label="支付状态">
            <a-select :default-value="99" style="width: 150px" @change="onChangePayStatus">
              <a-select-option :value="99">
                全部
              </a-select-option>
              <a-select-option :value="0">
                待支付
              </a-select-option>
              <a-select-option :value="1">
                已支付
              </a-select-option>
              <a-select-option :value="2">
                已完成
              </a-select-option>
              <a-select-option :value="3">
                订单取消
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item style="margin-left: 15px">
            <a-button type="primary" @click="submitGet">查询</a-button>
          </a-form-model-item>
          <a-form-model-item style="margin-left: 15px">
            <a-button type="primary" @click="showModal = true">新充值订单上传</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
      <a-card :bordered="false" style="margin-top: 10px">
        <a-table :locale="emptyText" :columns="tableColumns" :data-source="tableData" :pagination="pagination"
                 @change="changeTabel">
          <a slot="detail" slot-scope="item" @click="selectDetail(item)">查看明细</a>
          <span slot="action" slot-scope="item">
          <a-button v-if="item.orderStatus === 0" type="primary" @click="pay(item)">马上支付</a-button>
        </span>
        </a-table>
      </a-card>
    </div>
    <div v-if="showPage === 2">
      <a-card title="订单详情">
        <a-button slot="extra" @click="showPage = 1" type="primary" icon="left">返回</a-button>
        <div class="etc-recharge-payment-detail-title">
          <p class="etc-recharge-payment-detail-title-content">
            <span class="etc-recharge-payment-detail-title-content-label">下单日期:</span>
            <span class="etc-recharge-payment-detail-title-content-value">{{ detailObj.createTimeLabel }}</span>
          </p>
          <p class="etc-recharge-payment-detail-title-content">
            <span class="etc-recharge-payment-detail-title-content-label">充值订单总金额(元):</span>
            <span class="etc-recharge-payment-detail-title-content-value">{{ detailObj.amount }}</span>
          </p>
          <p class="etc-recharge-payment-detail-title-content">
            <span class="etc-recharge-payment-detail-title-content-label">支付状态:</span>
            <span class="etc-recharge-payment-detail-title-content-value">{{ detailObj.orderStatusLabel }}</span>
          </p>
          <p class="etc-recharge-payment-detail-title-content">
            <span class="etc-recharge-payment-detail-title-content-label">订单编号:</span>
            <span class="etc-recharge-payment-detail-title-content-value">{{ detailObj.orderNo }}</span>
          </p>
          <p class="etc-recharge-payment-detail-title-content">
            <span class="etc-recharge-payment-detail-title-content-label">写卡成功金额(元):</span>
            <span class="etc-recharge-payment-detail-title-content-value">{{ successPrice }}</span>
          </p>
        </div>
      </a-card>
      <a-card title="订单充值明细" :bordered="false" style="margin-top: 10px">
        <a-table :columns="detailColumns" :data-source="detailData" :pagination="false"></a-table>
      </a-card>
    </div>
    <a-modal :width="650" :title="null" :visible="showModal" :footer="null" @cancel="showModal = false"
             :maskClosable="false" :keyboard="false" @close="sureUpload">
      <div class="etc-recharge-payment-modal-wrapper">
        <div class="etc-recharge-payment-modal-body">
          <button v-if="uploadFileStatus === 0" class="etc-recharge-payment-modal-body-button" @click="openUploadFile">
            <a-icon type="plus" style="font-size: 16px;margin-bottom: 10px" />
            <p>选择EXCEL文件</p>
          </button>
          <div v-if="uploadFileStatus === 1" class="etc-recharge-payment-modal-body-uploadIng">
            <div class="etc-recharge-payment-modal-body-uploadIng-title">{{ complete }}</div>
            <div class="etc-recharge-payment-modal-body-uploadIng-content">
              <p style="color: #1890ff">{{ fileName }}</p>
              <p>
                <a-icon type="delete" @click="deleteFile" />
              </p>
            </div>
          </div>
          <div v-if="uploadFileStatus === 2" class="etc-recharge-payment-modal-body-uploadIng">
            <div class="etc-recharge-payment-modal-body-uploadIng-title">
              <a-icon type="check-circle" style="font-size: 28px" />
            </div>
            <div class="etc-recharge-payment-modal-body-uploadIng-content">
              <p style="color: #1890ff">{{ fileName }}</p>
              <p>
                <a-icon type="delete" @click="deleteFile" />
              </p>
            </div>
          </div>
          <div v-if="uploadFileStatus === 3">
            <div class="bms-flex-center">
              <div class="etc-recharge-payment-modal-body-uploadIng">
                <div class="etc-recharge-payment-modal-body-uploadIng-title" style="background-color: #ed4014">
                  <a-icon type="close-circle" style="font-size: 28px" />
                </div>
                <div class="etc-recharge-payment-modal-body-uploadIng-content">
                  <p style="color: #ed4014">{{ fileName }}</p>
                  <p>
                    <a-icon type="delete" @click="deleteFile" />
                  </p>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px">
              <a-table
                  :columns="errorColumns"
                  :data-source="errorData"
                  :pagination="false"
              />
            </div>
          </div>
        </div>
        <div class="etc-recharge-payment-modal-footer">
          <button class="etc-recharge-payment-modal-footer-button" :disabled="uploadFileStatus !== 2"
                  @click="sureUpload">确认上传
          </button>
        </div>
      </div>
      <input v-if="showModal" ref="inputUpload" type="file" @change="getUploadFile"
             style="display: none" />
    </a-modal>
    <a-modal v-model="showPay" title="支付结果" :keyboard="false" :maskClosable="false" :closable="false">
      <template slot="footer">
        <a-button key="back" @click="payResult">
          失败
        </a-button>
        <a-button key="submit" type="primary" @click="payResult">
          成功
        </a-button>
      </template>
      <p>支付是否成功?</p>
    </a-modal>
  </div>
</template>

<script>

import axios from "axios";
import { getToken, baseUrl } from "@/utils/request/request";
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";

export default {
  name: "index",
  data() {
    return {
      emptyText: { emptyText: "暂无数据" },
      locale: locale,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      complete: "0%",
      fileName: "",
      uploadFileStatus: 0, //0待上传 1正在上传 2上传成功 3上传失败
      showModal: false,
      showPage: 1,
      showPay: false,
      step: 0,
      pagination: false,
      searchObj: {
        page: 1,
        pageSize: 10,
        beginTime: null,
        endTime: null,
        orderStatus: 99 //订单状态,0-待支付,1-已支付,2-已完成,3-订单取消
      },
      successPrice: 0,
      tableColumns: [
        {
          title: "订单编号",
          dataIndex: "orderNo"
        },
        {
          title: "下单时间",
          dataIndex: "createTimeLabel"
        },
        {
          title: "订单充值总金额(元)",
          dataIndex: "amount"
        },
        {
          title: "支付状态",
          dataIndex: "orderStatusLabel"
        },
        {
          title: "充值订单明细",
          scopedSlots: { customRender: "detail" }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ],
      tableData: [],
      errorColumns: [
        {
          title: "车牌号",
          dataIndex: "plateNumber"
        },
        {
          title: "ETC卡号",
          dataIndex: "etcNo"
        },
        {
          title: "充值金额",
          dataIndex: "amount"
        },
        {
          title: "失败原因",
          dataIndex: "reason"
        }
      ],
      errorData: [],
      detailColumns: [
        {
          title: "车牌号",
          dataIndex: "plateNumber"
        },
        {
          title: "ETC卡号",
          dataIndex: "etcNo"
        },
        {
          title: "充值金额",
          dataIndex: "amount",
          width: "150"
        },
        {
          title: "订单状态",
          dataIndex: "orderStatusDesc"
        }
      ],
      detailData: [],
      detailObj: null
    };
  },
  methods: {
    moment,
    //选择开始时间
    onChangeStartTime(e, dateString) {
      console.log(dateString);
      this.searchObj.beginTime = dateString;
    },
    /*选择结束时间*/
    onChangeEndTime(e, dateString) {
      this.searchObj.endTime = dateString;
    },
    /*选择支付状态*/
    onChangePayStatus(e) {
      this.searchObj.orderStatus = e;
    },
    /*点击提交查询*/
    submitGet() {
      this.searchObj.page = 1;
      this.selectList();
    },
    /*查询订单*/
    selectList() {
      let self = this;
      let obj = {
        page: this.searchObj.page,
        pageSize: this.searchObj.pageSize,
        beginTime: this.searchObj.beginTime,
        endTime: this.searchObj.endTime,
        orderStatus: this.searchObj.orderStatus
      };
      if (this.searchObj.orderStatus === 99) {
        obj.orderStatus = "";
      }
      this.$http.postV("/depositCompany/queryCompanyOrder", obj).then(res => {
        if (res.code === 200) {
          let arr = res.data.list;
          arr.forEach((item) => {
            item.createTimeLabel = dayjs(new Date(item.createTime)).format("YYYY-MM-DD HH:mm:ss");
            switch (item.orderStatus) {
              case 0:
                item.orderStatusLabel = "待支付";
                break;
              case 1:
                item.orderStatusLabel = "已支付";
                break;
              case 2:
                item.orderStatusLabel = "已完成";
                break;
              case 3:
                item.orderStatusLabel = "订单取消";
                break;
            }
          });
          self.tableData = arr;
          self.pagination = { total: parseInt(res.data.total) };
        }
      });
    },
    /*分页*/
    changeTabel(e) {
      this.searchObj.page = e.current;
      this.searchObj.pageSize = e.pageSize;
      this.selectList();
    },
    /*查看详情*/
    selectDetail(e) {
      let self = this;
      this.$http.postV("/depositCompany/queryOrderDetail?companyOrderNo=" + e.orderNo).then(res => {
        if (res.code === 200) {
          self.detailObj = e;
          self.detailData = res.data;
          let price = 0;
          res.data.forEach((item) => {
            if (item.orderStatus === 6) {
              price += parseInt(item.amount);
            }
          });
          self.successPrice = price;
          self.showPage = 2;
        }
      });
    },
    /*下载模板*/
    downLoadTemplate() {
      let obj = {
        page: this.searchObj.page,
        pageSize: this.searchObj.pageSize,
        beginTime: this.searchObj.beginTime,
        endTime: this.searchObj.endTime,
        orderStatus: this.searchObj.orderStatus
      };
      this.$http.downLoadExcel("/depositCompany/download",obj).then(res => {
        console.log(res);
      });
    },
    /*删除文件*/
    deleteFile() {
      let self = this;
      this.$confirm({
        title: "删除?",
        content: "确定删除吗",
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          self.uploadFileStatus = 0;
          self.showModal = false;
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },
    /*点击上传文件*/
    openUploadFile() {
      this.$refs.inputUpload.click();
    },
    /*获取上传文件*/
    getUploadFile(e) {
      let file = e.target.files[0];
      this.fileName = file.name;
      if (this.fileName.search(".xlsx") === -1 && this.fileName.search(".xls") === -1) {
        this.$message.error("请上传excel文件");
      }
      let self = this;
      self.uploadFileStatus = 1;
      let params = new FormData();
      params.append("file", file);
      let config = {
        onUploadProgress: progressEvent => {
          let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + "%";
          self.complete = complete;
        },
        headers: {
          "x-access-token": getToken(),
          "Content-Type": "multipart/form-data"
        }
      };
      axios.post(baseUrl + `/depositCompany/uploadFile`,
          params, config).then((res) => {
        self.errorData = res.data.data;
        if (res.data.code === 200) {
          self.uploadFileStatus = 2;
        } else {
          self.uploadFileStatus = 3;
          self.$message.error(res.data.message);
        }
      });
    },
    /*支付结果再次查询*/
    payResult() {
      this.searchObj = {
        page: 1,
        pageSize: 10,
        beginTime: dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
        endTime: dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59",
        orderStatus: 99
      }
      this.showPay = false
      this.selectList()
    },
    /*支付*/
    pay(item) {
      let self = this;
      this.$http.postV("/depositCompany/createPayOrder?companyOrderNo=" + item.orderNo).then(res => {
        if (res.code === 200) {
          self.showPay = true;
          window.open(res.data);
        }
      });
    },
    /*确认上传*/
    sureUpload() {
      this.showModal = false;
      this.searchObj = {
        page: 1,
        pageSize: 10,
        beginTime: dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
        endTime: dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59",
        orderStatus: 99
      };
      this.selectList();
    }
  },
  created() {
    this.searchObj.beginTime = dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00";
    this.searchObj.endTime = dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59";
    this.selectList();
  }
};
</script>

<style scoped>
.etc-recharge-payment-template-downLoad {
  color: #1890ff;
}

.etc-recharge-payment-template-downLoad:hover {
  cursor: pointer;
}

.etc-recharge-payment-modal-body {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.etc-recharge-payment-modal-body-button {
  padding: 32px;
  color: #fff;
  background-color: #1890ff;
  border: 0;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  font-size: 14px;
  border-radius: 8px;
}

.etc-recharge-payment-modal-body-button:focus, .etc-recharge-payment-modal-body-button:hover {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.etc-recharge-payment-modal-footer {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.etc-recharge-payment-modal-footer-button {
  padding: 8px 80px;
  color: #fff;
  background-color: #1890ff;
  border: 0;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  font-size: 14px;
  border-radius: 2px;
}

.etc-recharge-payment-modal-footer-button:focus, .etc-recharge-payment-modal-footer-button:hover {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.etc-recharge-payment-modal-footer-button[disabled],
.etc-recharge-payment-modal-footer-button[disabled].active,
.etc-recharge-payment-modal-footer-button[disabled]:focus,
.etc-recharge-payment-modal-footer-button[disabled]:hover {
  color: rgba(0, 0, 0, .25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.etc-recharge-payment-modal-body-uploadIng {
  width: 26vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 5px 5px 5px #f1f1f1;
}

.etc-recharge-payment-modal-body-uploadIng-title {
  width: 20%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #448df7;
  border-radius: 12px 0 0 12px;
  text-align: center;
  line-height: 10vh;
}

.etc-recharge-payment-modal-body-uploadIng-content {
  width: 80%;
  height: 10vh;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 0 12px 12px 0;
  text-align: center;
  line-height: 10vh;
  color: #808695;
}

.etc-recharge-payment-modal-body-uploadIng-content > p:hover {
  cursor: pointer;
}

.etc-recharge-payment-detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.etc-recharge-payment-detail-title-content-label {
  color: #808695;
  margin-right: 10px;
}

.etc-recharge-payment-detail-title-content-value {
  color: #17233d;
}
</style>
